import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import gsap, { TweenMax } from 'gsap'
import {
  Bar,
  BarContainer,
  Container,
  NavigationLink,
  ExternalNavigationLink,
  NavigationButton,
} from './styled'

interface Props {
  text: string
  time: number
  url?: string
  useAnimation?: boolean
  variant?: string
  delay?: number
  styleVariant?: string
  linkType?: 'link' | 'button' | 'external'
  buttonCallback?: () => void
  withBar?: boolean
  coloroverwrite?: '#fff' | '#000'
}

export const TextNavigationLink: React.FC<Props> = ({
  delay,
  text,
  url,
  variant,
  useAnimation,
  styleVariant,
  linkType,
  withBar,
  coloroverwrite,
  buttonCallback,
}) => {
  const [readyState, setReadyState] = useState(false)
  const [firstAnimation, setFirstAnimation] = useState(true)

  const refBar = useRef(null)
  const refCont = useRef(null)
  const refLink = useRef(null)
  const navigationText = useRef(null)
  const tlRef = useRef(null)
  const fadeinTlRef = useRef(null)

  const createCallToActionIntroAnimation = () => {
    const tl = gsap.timeline({
      repeat: 0,
      delay,
      timeScale: 1,
      paused: true,
      onComplete: () => {
        setFirstAnimation(false)
        setReadyState(true)
      },
    })

    tl.fromTo(
      refBar.current,
      0.5,
      {
        scaleX: 0,
        opacity: 0,
      },
      {
        transformOrigin: 'left',
        scaleX: 1,
        opacity: 1,
      },
      0,
    )
    tl.to(
      refBar.current,
      3,
      { transformOrigin: 'left', scaleX: 1, opacity: 1 },
      0.5,
    )
    return tl
  }
  const createCallToActionAnimation = () => {
    if (!refBar.current) {
      return null
    }
    const tl = gsap.timeline({
      repeat: -1,
      timeScale: 1,
      paused: true,
      delay,
    })

    tl.fromTo(
      refBar.current,
      0.5,
      {
        scaleX: 1,
        opacity: 1,
      },
      {
        transformOrigin: 'right',
        scaleX: 0,
      },
      0,
    )
    tl.to(
      refBar.current,
      0.5,
      {
        transformOrigin: 'left',
        scaleX: 1,
        opacity: 1,
      },
      0.5,
    )
    tl.to(refBar.current, 2.5, {}, 1)
    return tl
  }

  useEffect(() => {
    if (readyState) {
      // eslint-disable-next-line
      tlRef.current?.kill()

      if (firstAnimation) {
        setReadyState(false)
        tlRef.current = createCallToActionIntroAnimation()
      } else {
        tlRef.current = createCallToActionAnimation()
      }
      tlRef.current.play()
    }
  }, [readyState])

  useEffect(() => {
    // simple fade in with delay property
    // start by checking the referenced

    if (!refLink.current || !navigationText.current) {
      // return out of the effect if not available
      return null
    }
    const tl = gsap.timeline({
      onComplete: () => {
        if (withBar) {
          setReadyState(true)
        }
      },
    })

    tl.fromTo(
      refLink.current,
      1,
      {
        opacity: 0,
      },
      {
        ease: 'sine.out',
        opacity: 1,
      },
      0,
    )
    const secondAnimationStart = 1.5 + delay

    tl.fromTo(
      navigationText.current,
      0.3,
      {
        opacity: 0,
      },
      {
        ease: 'sine.out',
        opacity: 1,
      },
      secondAnimationStart,
    )

    if (useAnimation) {
      fadeinTlRef.current = tl
      tl.play()
    }

    return () => {
      if (fadeinTlRef.current) {
        fadeinTlRef.current.kill()
      }
      // eslint-disable-next-line
      tlRef.current?.kill()
    }
  }, [])

  function returnLinkType(linkTypeToCheck) {
    switch (linkTypeToCheck) {
      case 'link':
        return (
          <NavigationLink
            coloroverwrite={coloroverwrite}
            variant={variant}
            ref={refLink}
            to={url}
            data-url
          >
            <span ref={navigationText} data-url>
              {text}
            </span>
          </NavigationLink>
        )
        break
      case 'external':
        return (
          <ExternalNavigationLink
            coloroverwrite={coloroverwrite}
            variant={variant}
            ref={refLink}
            href={url}
            target="_blank"
            data-url
          >
            <span ref={navigationText} data-url>
              {text}
            </span>
          </ExternalNavigationLink>
        )
        break
      default:
        return (
          <NavigationButton
            type="button"
            ref={refLink}
            onClick={buttonCallback && buttonCallback}
            variant={variant}
          >
            <span ref={navigationText} data-url>
              {text}
            </span>
          </NavigationButton>
        )
    }
  }

  return (
    <Container variant={styleVariant}>
      {withBar && (
        <BarContainer ref={refCont}>
          <Bar variant={variant} ref={refBar} />
        </BarContainer>
      )}
      {returnLinkType(linkType)}
    </Container>
  )
}

TextNavigationLink.defaultProps = {
  delay: 0,
  useAnimation: true,
  styleVariant: 'all',
  linkType: 'link',
  buttonCallback: null,
  withBar: true,
  variant: 'dark',
  url: null,
  coloroverwrite: null,
}

TextNavigationLink.propTypes = {
  delay: PropTypes.number,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  useAnimation: PropTypes.bool,
  styleVariant: PropTypes.string,
  linkType: PropTypes.oneOf(['link', 'button', 'external']),
  buttonCallback: PropTypes.func,
  withBar: PropTypes.bool,
  variant: PropTypes.string,
  coloroverwrite: PropTypes.oneOf(['#fff', '#000']),
}
