import styled, { css, StyledComponent } from '@xstyled/styled-components'
import { variant, breakpoints } from '@xstyled/system'

import { Link } from '@reach/router'

export const Container: StyledComponent<
  'div',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.div`
  margin-bottom: 28px;
  ${breakpoints({
    xs: css`
      &:nth-child(2) {
        flex-flow: row-reverse;
        text-align: right;
      }
    `,
    md: css`
      &:last-child {
        flex-flow: initial;
        text-align: left;
      }
    `,
  })}

  ${variant({
    prop: 'variant',
    variants: {
      /* eslint-disable indent */
      xsOnly: css`
        ${breakpoints({
          xs: css`
            display: flex;
          `,
          md: css`
            display: none;
          `,
          xl: css`
            display: none;
          `,
          xxl: css`
            display: none;
          `,
        })}
      `,
      lgDown: css`
        ${breakpoints({
          xs: css`
            display: flex;
          `,
          lg: css`
            display: none;
          `,
        })}
      `,
      mdDown: css`
        ${breakpoints({
          xs: css`
            display: flex;
          `,
          xl: css`
            display: none;
          `,
        })}
      `,
      mdOnly: css`
        ${breakpoints({
          xs: css`
            display: none;
          `,
          md: css`
            display: flex;
          `,
          xl: css`
            display: flex;
          `,
          xxl: css`
            display: none;
          `,
        })}
      `,
      all: css`
        display: flex;
      `,
    },
    /* eslint-enable indent */
  })};
`

export const BarContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 25px;
`

export const Bar = styled.div`
  height: 1px;
  background-color: ${(props) => (props.variant === 'light' ? '#000' : '#fff')};

  transform: scaleX(0);

  opacity: 0;
  width: 100%;

  &:link,
  &:visited,
  &:active {
    color: ${(props) => (props.variant === 'light' ? '#000' : '#fff')};
  }
`

interface VariantProps {
  variant: string
}

export const NavigationLink = styled(Link)<VariantProps>`
  font-size: xs;
  font-weight: bold;
  margin: sm;
  opacity: 0;

  &:link,
  &:visited,
  &:active {
    color: secondary;
    color: ${(props) => props.coloroverwrite && props.coloroverwrite};
  }
  text-decoration: none;
  text-transform: uppercase;
  line-height: 105%;
`

export const ExternalNavigationLink = styled.a<VariantProps>`
  font-size: xs;
  font-weight: bold;
  margin: sm;
  opacity: 0;

  &:link,
  &:visited,
  &:active {
    color: secondary;
    color: ${(props) => props.coloroverwrite && props.coloroverwrite};
  }
  text-decoration: none;
  text-transform: uppercase;
  line-height: 105%;
`

export const NavigationButton = styled.button`
  outline: none;
  background: none;
  border: none;
  font-size: xs;
  font-weight: bold;
  margin: sm;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 105%;
  color: ${(props) => (props.variant === 'light' ? '#000' : '#fff')};
`
