import React, { useRef, useState, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import PageWrapperAlt from '@/components/PageWrapper/PageWrapperAlt'
import VisibilitySensor from 'react-visibility-sensor'
import styled, { Box } from '@xstyled/styled-components'
import containerWidth from '@/modules/utils/containerWidth'
import { NewsOverviewItem } from '@/components/NewsOverviewItem'
import { useContentInProp } from '@/hooks/useContentInProp'
import { getIsMobile } from '@/modules/utils/getMobile'

export const query = graphql`
  query allCmsNews {
    allCmsNews(
      sort: { fields: date, order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          id
          slug
          type
          title
          excerpt
          body
          desktop_body
          created_on
          city
          thumbnail {
            data {
              url
            }
          }
          desktop_images {
            id
            type
            title
            data {
              url
            }
          }
        }
      }
    }
  }
`

const chunk = (n, xs) =>
  Array(Math.ceil(xs.length / n))
    .fill(0)
    .map((_, i) => xs.slice(i * n, i * n + n))

const linkTextFromType = (type) =>
  ({
    video: 'Watch',
    podcast: 'Listen',
    default: 'Read',
  }[type])

const transformEdge = ({ node }) => ({
  pretitle: 'Article',
  title: node.title,
  excerpt: node.excerpt,
  image: node?.thumbnail?.data?.url,
  links: [
    {
      link: linkTextFromType(node.type),
      url: `/latest-insights/article/${node.slug}`,
      variant: 'all',
    },
  ],
})

interface NewsOverviewData {
  allCmsNews: GatsbyTypes.CmsNewsConnection
}

const Index: React.FC<{ data: NewsOverviewData }> = ({ data }) => {
  const inProp = useContentInProp()
  const rowWrapperRef = useRef(null)
  const [maxWidth, setMaxWidth] = useState(containerWidth() / 2)
  const content = data.allCmsNews.edges.map(transformEdge)
  const rows = chunk(2, content)

  const getMaxWidth = () => {
    const newWidth = containerWidth() / 2
    return newWidth
  }

  const onResize = () => {
    setMaxWidth(getMaxWidth())
  }
  const addDomEvents = () => {
    window.addEventListener('resize', onResize)
  }

  const removeDomEvents = () => {
    window.removeEventListener('resize', onResize)
  }

  useLayoutEffect(() => {
    addDomEvents()

    onResize()
    return () => {
      removeDomEvents()
    }
  }, [])

  if (!content) {
    return null
  }
  return (
    <>
      {inProp && (
        <>
          <PageWrapperAlt>
            <Container ref={rowWrapperRef}>
              {rows.map((row, rowIndex) => {
                const currentRow = [...row]
                if (currentRow.length === 1) {
                  const firstItem = rows[0] && rows[0][0]
                  if (firstItem) {
                    currentRow.push(firstItem)
                  }
                }

                return (
                  <NewsRow row>
                    {row?.map((col, index) => {
                      const isEven = index % 2 === 0
                      const isRowEven = rowIndex % 2 === 0

                      return (
                        <Box key={col.title} col={{ xs: 1, md: 0.5 }}>
                          <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                              <NewsOverviewItem
                                visible={isVisible}
                                isEven={isEven}
                                rowEven={isRowEven}
                                maxWidth={getIsMobile() ? 'inherit' : maxWidth}
                                col={col}
                              />
                            )}
                          </VisibilitySensor>
                        </Box>
                      )
                    })}
                  </NewsRow>
                )
              })}
            </Container>
            <Background />
          </PageWrapperAlt>
        </>
      )}
    </>
  )
}

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #292828;
`

const Container = styled.div`
  width: 100%;
`

const NewsRow = styled(Box)`
  h1 {
    padding-top: 20px;
    font-family: title;
    font-size: 29px;
    letter-spacing: 1px;
  }
  p {
    font-family: primary;
    font-size: 16px;
    margin-bottom: 50px;
  }
`

export default Index
