import React, { useEffect, ReactNode, useState } from 'react'
import PubSub from 'pubsub-js'
import Helmet from 'react-helmet'
import { APP_VIEW_UPDATE } from '@/events'
import { StaticQuery, graphql } from 'gatsby'
import { ContainerAlt } from './styled'

interface Props {
  children: ReactNode
  scrollable?: boolean
  noPaddingMobile?: boolean
  useScaling?: boolean
  withStyleScrollingHighJack?: boolean
}

const mobileBreakpoint = 960
/*
 *  - Events:
 *    - publish:
 *      - APP_VIEW_UPDATE (initial and on window resize)
 */
const PageWrapperAlt: React.FC<Props> = ({ children }) => {
  const [mobileView, setMobileView] = useState(null)

  const onResize = () => {
    const newMobileView =
      document.documentElement.clientWidth <= mobileBreakpoint

    const eventData = newMobileView ? 'mobile' : 'desktop'
    setMobileView(newMobileView ? 'mobile' : 'desktop')
    if (mobileView === null || newMobileView !== mobileView) {
      PubSub.publish(APP_VIEW_UPDATE, eventData)
    }
  }

  const addDomEvents = () => {
    window.addEventListener('resize', onResize)
  }

  const removeDomEvents = () => {
    window.removeEventListener('resize', onResize)
  }

  useEffect(() => {
    addDomEvents()
    onResize()
    return () => {
      removeDomEvents()
    }
  }, [mobileView])

  return (
    <StaticQuery<GatsbyTypes.SiteTitleQuery2Query>
      query={graphql`
        query SiteTitleQuery2 {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            meta={[
              {
                content:
                  'A ruthlessly effective cloud-native core-banking engine',
                name: 'description',
              },
              {
                content: 'banking, cloud-native, core-banking',
                name: 'keywords',
              },
              {
                content: data.site.siteMetadata.title,
                name: 'og:title',
              },
              {
                content:
                  'A ruthlessly effective cloud-native core-banking engine',
                name: 'og:description',
              },
              {
                content: '/images/share-image.jpg',
                name: 'og:image',
              },
              {
                content: '#da532c',
                name: 'msapplication-TileColor',
              },
              {
                content: '/images/favicon/browserconfig.xml',
                name: 'msapplication-config',
              },
              {
                content: '#ffffff',
                name: 'theme-color',
              },
              {
                content: 'width=device-width, initial-scale=0.95',
                name: 'viewport',
              },
            ]}
            link={[
              {
                href: '/fonts.css',
                rel: 'stylesheet',
                type: 'text/css',
              },
              {
                href: '/images/favicon/apple-touch-icon.png',
                rel: 'apple-touch-icon',
                sizes: '180x180',
              },
              {
                href: '/images/favicon/favicon-32x32.png',
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
              },
              {
                href: '/images/favicon/favicon-16x16.png',
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
              },
              {
                href: '/images/favicon/site.webmanifest',
                rel: 'manifest',
              },
              {
                href: '/images/favicon/safari-pinned-tab.svg',
                rel: 'mask-icon',
                color: '#5bbad5',
              },
              {
                href: '/images/favicon/favicon.ico',
                rel: 'shortcut icon',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <ContainerAlt>{children}</ContainerAlt>
        </>
      )}
    />
  )
}

export default PageWrapperAlt
