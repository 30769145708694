import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from '@xstyled/styled-components'
import proportion from '@/modules/utils/proportion'
import { breakpoints } from '@xstyled/system'
import { TextNavigationLink } from '@/components/TextNavigationLink'
import gsap from 'gsap'

/* eslint-disable indent */

interface colProps {
  pretitle: string
  title: string
  excerpt: string
  image?: string
  links: [
    {
      link: string
      url: string
      variant: string
    },
  ]
}

export const NewsOverviewItem: React.FC<{
  visible: boolean
  isEven: boolean
  rowEven: boolean
  maxWidth?: number | 'inherit'
  col: colProps
}> = ({ visible, isEven, rowEven, maxWidth, col }) => {
  const [linkVisible, setLinkVisible] = useState(false)
  const [visibleState, setVisibleState] = useState(false)
  const [animated, setAnimated] = useState(false)
  const backgroundRef = useRef(null)
  const pretitleRef = useRef(null)
  const imageRef = useRef(null)
  const titleRef = useRef(null)
  const excerptRef = useRef(null)
  const timelineRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (visible && !visibleState) {
      setVisibleState(true)
    }
  }, [visible])

  useEffect(() => {
    if (visibleState) {
      setAnimated(true)
    }
  }, [visibleState])

  useEffect(() => {
    const delay = isEven ? 0.2 : 0
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    clearTimeout(timeoutRef.current)
    const tl = gsap.timeline({
      paused: true,
      delay,
    })
    if (animated) {
      timeoutRef.current = setTimeout(() => {
        setLinkVisible(true)
      }, 600)
    }

    tl.timeScale(0.5)

    tl.set([backgroundRef.current], {
      opacity: 1,
      transformOrigin: 'top',
    })

    tl.set(imageRef.current, {
      opacity: 1,
    })

    tl.fromTo(
      backgroundRef.current,
      0.4,
      {
        scaleY: 0,
      },
      {
        scaleY: 1,
      },
    )

    tl.fromTo(
      pretitleRef.current,
      0.2,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
      },
      '-=0.15',
    )

    tl.fromTo(
      imageRef.current,
      0.5,
      {
        clipPath: 'inset(0px 0px 100% 0px)',
      },
      {
        clipPath: 'inset(0px 0px 0px 0px)',
      },
      '-=0.15',
    )

    tl.fromTo(
      titleRef.current,
      0.2,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
      },
      '-=0.15',
    )

    tl.fromTo(
      excerptRef.current,
      0.2,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
      },
      '-=0.15',
    )

    if (timelineRef.current) {
      timelineRef.current.kill()
    }

    if (animated) {
      tl.play()
    }

    timelineRef.current = tl
  }, [animated])

  return (
    <NewsArticle isEven={isEven}>
      <Background ref={backgroundRef} isEven={!isEven} rowEven={rowEven} />
      <NewsArticleWrap maxWidth={maxWidth} isEven={isEven}>
        <NewsArticleInner>
          <NewsType ref={pretitleRef}>{col.pretitle}</NewsType>
          <NewsImageWrap>
            <NewsImage src={col.image} ref={imageRef} />
          </NewsImageWrap>
          <h1 ref={titleRef}>
            <a href={col.links[0].url}>{col.title}</a>
          </h1>
          <p ref={excerptRef}>{col.excerpt}</p>
          {linkVisible &&
            col.links?.map((linkEntry) => (
              <TextNavigationLink
                key={linkEntry.url}
                withBar
                variant="dark"
                time={1}
                url={linkEntry.url}
                useAnimation
                text={linkEntry.link}
                styleVariant={linkEntry.variant}
              />
            ))}
        </NewsArticleInner>
      </NewsArticleWrap>
    </NewsArticle>
  )
}

const NewsArticle = styled.div<{ isEven: boolean }>`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: ${(props) => props.isEven && 'flex-end'};
  ${breakpoints({
    md: css`
      height: 100vh;
    `,
  })}
  width: 100%;
`

const Background = styled.div<{ isEven: boolean; rowEven: boolean }>`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  ${(props) =>
    props.isEven &&
    css`
      background: rgb(61, 97, 85);
      background: linear-gradient(
        45deg,
        rgba(61, 97, 85, 1) 0%,
        rgba(88, 125, 99, 1) 100%
      );
    `}

  ${breakpoints({
    md: css`
      background: none;
      ${(props) =>
        props.isEven &&
        props.rowEven &&
        css`
          background: rgb(61, 97, 85);
          background: linear-gradient(
            45deg,
            rgba(61, 97, 85, 1) 0%,
            rgba(88, 125, 99, 1) 100%
          );
          background-size: 150%;
          background-position: center center;
        `}
      ${(props) =>
        !props.isEven &&
        !props.rowEven &&
        css`
          background: rgb(61, 97, 85);
          background: linear-gradient(
            45deg,
            rgba(61, 97, 85, 1) 0%,
            rgba(88, 125, 99, 1) 100%
          );
          background-size: 150%;
          background-position: center center;
        `}
    `,
  })}
`

const NewsArticleWrap = styled.span<{
  maxWidth: number | 'inherit'
  isEven: boolean
}>`
  position: relative;
  align-self: center;
  a {
    color: secondary;
  }
  flex: 1;
  max-width: ${(props) => props.maxWidth}px;
  justify-content: center;
  display: flex;
  z-index: 1;
  ${breakpoints({
    md: css`
      height: 100vh;
    `,
  })}
  padding: 20px 0;
  /* eslint-disable indent */
  ${breakpoints({
    md: css`
      height: unset;
      justify-content: ${(props) =>
        !props.isEven ? 'flex-end' : 'flex-start'};
      padding-right: ${(props) => !props.isEven && proportion(50, 'desktop')};
      padding-left: ${(props) => props.isEven && proportion(50, 'desktop')};
    `,
    xl: css`
      padding-right: ${(props) => !props.isEven && '50px'};
      padding-left: ${(props) => props.isEven && '50px'};
    `,
  })}
`
const NewsArticleInner = styled.div`
  width: 80%;
  min-height: 100vh;
  ${breakpoints({
    md: css`
      min-height: unset;
      height: 500px;
    `,
  })}
`
const NewsImage = styled.div<{ src: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(0px 0px 100% 0px);
  background-position: center center;
  background-image: url(${(props) => props.src});
  background-size: cover;
`

const NewsImageWrap = styled.div`
  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 50%;
`

const NewsType = styled.span`
  font-family: primary;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 30px;
  display: block;
`
